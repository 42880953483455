<template>
   <v-expand-transition>
      <v-form ref="form">
         <div v-if="typeof subject.id === 'string' || subject.id >= 0">
            <v-card-text class="pt-9">
               <v-row>
                  <v-col cols="12" md="6">
                     <v-text-field v-model="form.name" label="Name" placeholder="Nama Organisasi" outlined hide-details required :rules="[v => !!v]">
                        <template slot="append-outer">
                           <v-btn large height="56" width="56" icon @click="$refs.subjectLogo.click()">
                              <v-avatar v-if="form.Logo" tile size="56">
                                 <img :src="setUrlFromImage(form.Logo)" alt="Logo" style="object-fit: cover;" @error="() => { form.Logo = '' }">
                              </v-avatar>
                              <v-icon v-else tile size="68">
                                 mdi-image
                              </v-icon>
                              <input ref="subjectLogo" type="file" accept="image/*" alt="subjectLogo" style="display:none;" @change="attachmentPreview">
                           </v-btn>
                        </template>
                     </v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                     <v-text-field v-model="form.identity" label="Code" placeholder="Kode Organisasi" outlined hide-details />
                  </v-col>
                  <v-col v-if="$store.state.user.code === 'SPA'" cols="12" md="3">
                     <v-select v-model="form.zone" :items="zones" label="Management Zone" placeholder="Zona Manajemen" outlined hide-details required :rules="[v => !!v]"
                        item-text="name" item-value="zone"
                        :return-object="false" :menu-props="{ bottom: true, offsetY: true }"
                        />
                  </v-col>
               </v-row>
               <v-row>
                  <v-col cols="12" md="4">
                     <v-autocomplete :items="filteredFloors" label="Floor" placeholder="Ketik lalu PILIH" outlined hide-details
                        item-text="name" item-value="identity" no-data-text="Typeahead and choose one"
                        prepend-inner-icon="mdi-database-search"
                        :return-object="true"
                        :menu-props="{ offsetY: true }"
                        :search-input.sync="search.input"
                        @change="(item) => search.floor = item"
                        >
                        <template slot="append-outer">
                           <v-btn icon large height="56" width="56" tile :disabled="!search.floor.identity" @click="insertFloor">
                              <v-icon size="60" color="secondary">
                                 mdi-plus-box-outline
                              </v-icon>
                           </v-btn>
                        </template>
                        <template v-slot:item="data">
                           <v-list-item-content>
                              <v-list-item-title v-text="data.item.name" />
                              <v-list-item-subtitle>
                                 {{ data.item.identity }}
                              </v-list-item-subtitle>
                           </v-list-item-content>
                        </template>
                     </v-autocomplete>
                  </v-col>
               </v-row>
               <v-row v-if="search.floors.length" class="mt-0 mb-n6">
                  <v-col cols="12">
                     <v-chip-group active-class="accent--text" column>
                        <v-chip v-for="(tag, idx) in search.floors" :key="idx" large label outlined close style="max-width: 220px;"
                           @click:close="search.floors.splice(idx, 1)"
                           >
                           <v-icon left size="18">
                              mdi-stairs
                           </v-icon>
                           <v-list-item-content>
                              <v-list-item-title class="mb-0" style="line-height: 1.4rem;">
                                 {{ tag.name }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                 {{ tag.identity }}
                              </v-list-item-subtitle>
                           </v-list-item-content>
                        </v-chip>
                     </v-chip-group>
                  </v-col>
               </v-row>
            </v-card-text>
            <v-divider />
            <v-card-text class="pb-6">
               <v-row class="mt-0">
                  <v-col cols="12" class="py-0 d-flex">
                     <v-btn v-if="$hasRole(subject.kind.toLowerCase(), 'CU')" x-large color="accent" depressed class="mr-4" :loading="$store.state.process === 'updateSubject'" @click="updateRecord">
                        Update
                     </v-btn>
                     <v-btn x-large color="accent" depressed outlined class="mr-4" @click="subject.id = -1">
                        {{ $store.state.process === 'updateSubject' ? 'Close' : 'Cancel' }}
                     </v-btn>
                     <v-spacer />
                  </v-col>
               </v-row>
            </v-card-text>
            <v-divider />
         </div>
      </v-form>
   </v-expand-transition>
</template>

<script>
import { imageFromUrl, blobImageToBase64 } from '@/utils/nirtara'
import { organization } from '@/utils/fields.js'
export default {
   name: 'NOrganizationForm',

   props: {
      subject: { type: Object },
      zones: { type: Array },
      floors: { type: Array },
   },
   data: () => ({
      isUsed: false,
      form: Object.assign({}, organization),
      search: { input: null, floor: {}, floors: [] },
   }),

   computed: {
      setUrlFromImage () {
         return image => imageFromUrl(image)
      },
      filteredFloors () {
         return (this.search.input && this.search.input.length) > 0 ? this.floors : []
      },
   },
   watch: {
      'subject.id' (id) {
         if (id < 0) return
         id === 0 && this.$refs.form.reset()
         this.form = Object.assign({}, organization, typeof id === 'string' ? this.subject : { id: 0 })
         this.search.floors = []
         if (id !== 0) {
            this.subject.Floors && (this.search.floors = this.subject.Floors.map(j => Object.assign(j, this.floors.find(f => f.identity === j.identity) || { name: j.identity })))
         }
      },
   },
   methods: {
      attachmentPreview (event) {
         blobImageToBase64(this.$refs[event.target.alt].files[0])
         .then(base64 => {
            switch (event.target.alt) {
               default: console.log(`${event.target.alt}: ${base64.substr(0, 32)}....`)
                  break
               case 'subjectLogo': this.form.Logo = base64
                  break
            }
         })
      },
      insertFloor () {
         const { search } = this
         search.floors.push({ Floor: [], identity: search.floor.identity, name: search.floor.name })
         search.floor = {}
         search.input = null
      },
      updateRecord () {
         if (!this.$refs.form.validate()) return
         this.$emit('update', Object.assign({}, this.form, { Floors: this.search.floors.map(j => { return { Floor: [], identity: j.identity } }) }))
      },
   },
}
</script>

<style lang="scss" scoped>
.col-12 {
   padding: 0 12px 20px 12px;
}
::v-deep .v-text-field--outlined .v-input__append-outer {
   margin: auto 0 auto 16px;
}
.v-chip.v-chip--outlined .v-icon {
   font-size: 36px !important;
}
::v-deep .v-chip .v-chip__close.v-icon {
   font-size: 22px !important;
}
// .v-chip .v-avatar {
//    height: 40px !important;
//    min-width: 40px !important;
//    width: 40px !important;
//    margin-right: 10px;
// }
::v-deep .v-chip .v-chip__content {
   letter-spacing: 0;
}
.v-btn:not(.v-btn--round).v-size--x-large {
   min-width: 115px;
}
</style>
